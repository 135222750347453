body {
    font-family: $font-roboto;
    color: $color-text;
    font-size: 12px;
    line-height: 1.4;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// https://codepen.io/sambible/post/browser-scrollbar-widths
$scrollbarWidths: 12, 15, 17;

body.page {
    background-color: $color-bg ;
    background-image: url(../../../img/bg-union.svg);
    background-repeat: no-repeat;
    background-position: right bottom;
    min-height: 100vh;
    padding: 64px 0 60px 0 !important;
    overflow-x: auto !important;
    overflow-y: scroll !important;

    @each $width in $scrollbarWidths {
        &[style*='padding-right: #{$width}px'] {
            padding-right: #{$width}px !important;
            background-position: right #{$width}px bottom;

            header {
                padding-right: #{$width}px;
            }

            .MuiFab-root.MuiFab-primary.fab-fixed {
                right: #{$width + 20}px;
            }

            &[style*='overflow'] {
                overflow-y: hidden !important;
            }
        }
    }
}
