.pt {
    &--10 {
        padding-top: 10px;
    }

    &--20 {
        padding-top: 20px;
    }

    &--30 {
        padding-top: 30px;
    }
}

.pb {
    &--10 {
        padding-bottom: 10px;
    }

    &--20 {
        padding-bottom: 20px;
    }

    &--30 {
        padding-bottom: 30px;
    }
}