.mt {
    &--20 {
        margin-top: 20px;
    }
    
    &--30 {
        margin-top: 30px;
    }
}

.mb {
    &--10 {
        margin-bottom: 10px;
    }

    &--20 {
        margin-bottom: 20px;
    }
}