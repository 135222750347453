.error-box {
    width: 100%;
    max-width: 416px;
    margin: 30px auto 0;
    color: #14213D;
    background: #FFF;
    border-radius: 6px;
    padding: 28px 30px 36px;

    h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;
        margin-bottom: 30px;
    }

    .p-large {
        font-size: 18px;
        font-weight: 400;
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .button-action {
        margin-top: 20px;
    }
}