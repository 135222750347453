.text--center {
    text-align: center;
}

.text--right {
    text-align: right;
}

.text--info {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
}