.form {
    background: #fff;
    max-width: 400px;
    margin: 0 auto;
    padding: 24px;

    &--bordered {
        border: 1px solid $color-border;
        border-radius: 10px;
    }

    textarea {
        width: 100%;
        background: #FFF;
        font-size: 13px;
        line-height: 1.4;
        font-family: $font-roboto;
        padding: 10px;
    }

    .form-title {
        display: block;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 40px;

        &--inline {
            display: inline-block;
        }

        &--small {
            font-size: 18px;
            margin-bottom: 16px;
        }
    }

    .MuiTextField-root {
        margin-bottom: 20px;
    }

    .MuiFormControl-root {
        width: 100%;
        margin-bottom: 10px;
    }

    .MuiFormControlLabel-labelPlacementStart {
        width: 100%;
        justify-content: space-between;
        margin-left: 0;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background: rgba(#02BC8F, 0.5);
    }

    .MuiSwitch-colorSecondary.Mui-checked {
        color: #02BC8F;
    }

    .MuiRadio-colorSecondary.Mui-checked {
        color: #0000008a;
    }

    .MuiChip-root {
        color: #FFF;
        background-color: #77A1FE;

        .MuiChip-deleteIcon {
            color: #FFF;
        }
    }
}
