// Fonts
$font-roboto: 'Roboto', sans-serif;

// Colors
$color-text: #333;
$color-bg: #F1F2F2;
$color-bg-input: #F4F6F8;
$color-bg-blue: #678DE9;
$color-border: #D8DDE4;
$color-error: #DC3636;
$color-blue: #14213D;
$color-blue-secondary: #CACED6;
$color-yellow: #ECAC21;

// Breakpoints
$bp-laptop: 'screen and (max-width: 1441px)';
$bp-tablet: 'screen and (max-width: 992px)';
$bp-mobile: 'screen and (max-width: 640px)';

// Other
$hover-opacity: .75;
