.header-container {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
    z-index: 500;

    .MuiAppBar-root {
        background-color: #14213D;
        box-shadow: none;
    }

    .MuiToolbar-root {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;

        .col-left {
            display: flex;
            align-items: stretch;
            min-height: 64px;

            &.col-left-simple {
                width: 100%;
            }

            .col-left-back {
                display: flex;
                align-items: center;
                min-width: 250px;

                .MuiLink-root {
                    display: inline-flex;
                    height: 64px;
                }

                .link-back {
                    display: inline-block;
                    color: #FFF;
                    height: 24px;
                    margin-left: 20px;
                }
            }

            .col-left-title {
                width: 100%;
                font-size: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .col-right {
            &.fix-width {
                display: flex;
                justify-content: flex-end;
                min-width: 250px;
            }
        }

        .header-logo-box {
            display: flex;

            .MuiLink-root {
                display: flex;
                align-items: center;
            }

            img {
                width: 128px;
                height: 38px;
            }
        }

        .main-nav, .col-right {
            display: flex;
            margin-left: 20px;

            a.MuiLink-root {
                color: inherit;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                font-size: 12px;
                padding: 4px 10px 0;
                margin-right: 5px;
                min-width: 102px;
                text-decoration: none;

                &.active {
                    background: #2445A1;
                }

                .MuiSvgIcon-root {
                    margin-bottom: 4px;
                }
            }
        }

        .button-notifications {
            padding: 0;
            margin-right: 25px;

            a.MuiLink-root {
                height: 64px;
            }
        }

        .profile-character {
            display: inline-block;
            width: 32px;
            height: 32px;
            background: #2445A1;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            padding-top: 3px;
            margin-right: 8px;
            border-radius: 50%;
        }

        .profile-name {
            font-size: 13px;
            font-weight: 600;
            text-transform: none;
            text-decoration: underline;
        }
    }
}

.profile-menu {
    .MuiListItemIcon-root {
        min-width: 34px;
    }
}
