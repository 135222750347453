.page-login {
    padding-top: 0;
    margin-top: 0;

    .logo-box {
        width: 100%;
        max-width: 276px;
        margin: 50px auto;

        .version-number {
            display: block;
            color: #A8B2C1;
            font-weight: 700; // bold
        }
    }

    .form {
        .MuiButton-root {
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .MuiLink-root {
            display: block;
            text-align: center;

            &.link-back {
                display: inline-block;
                vertical-align: middle;
                margin-right: 8px;
            }

            &.link-authorize {
                margin-top: 20px;
            }
        }

        &-language {
            margin-top: 10px;
        }

        .text--info {
            margin-bottom: 20px;
            display: block;

            &--without-margin {
                margin-bottom: 0;
            }
        }
    }
}
