.hidden {
    display: none !important;
}

body {
    .MuiButton-root {
        letter-spacing: 0.5px;
    }

    .button-numeric {
        letter-spacing: 0;
    }

    .MuiInputBase-input {
        font-weight: normal;
    }

    .MuiFormLabel-root {
        font-weight: normal;
    }

    .MuiButton-root.Mui-disabled {
        color: #9f9f9f;
    }
}
